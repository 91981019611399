<template>
    <div>
        <el-divider class="divi"></el-divider>
        <el-form class="centered" ref="addFormRef" :model="addForm" label-width="110px" label-position="top" :rules="addFormRules">
        <el-form-item label="数据域名称" prop="datafileName">
            <el-input style="width: 256px;" v-model="addForm.datafileName" placeholder="请输入修改后的名称"></el-input>
        </el-form-item>

        <el-row :gutter="20">
            <el-col :span="3">
                <span style="color: #5F6297;font-size: 14px; line-height: 32px; text-align: left;">添加相关区域：</span>
            </el-col>
            <el-col :span="3">
                <el-select v-model="addForm.coalValue" placeholder="选择项目">
                    <el-option v-for="item in coaloptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button class="add" type="primary">+</el-button>
            </el-col>
            <el-col :span="3">
                <el-select v-model="addForm.systemValue" placeholder="选择系统">
                    <el-option v-for="item in systemoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button class="add" type="primary">+</el-button>
            </el-col>
            <el-col :span="3">
                <el-select v-model="addForm.subsystemValue" placeholder="选择子系统">
                    <el-option v-for="item in subsystemoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button class="add" type="primary">+</el-button>
            </el-col>
            <el-col :span="3">
                <el-select v-model="addForm.deviceValue" placeholder="选择设备">
                    <el-option v-for="item in deviceoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button class="add" type="primary">+</el-button>
            </el-col>
        </el-row>

        <el-form-item label="数据域简介" prop="datafileDes">
            <el-input style="width: 256px;" v-model="addForm.datafileDes" placeholder="请输入简介"></el-input>
        </el-form-item>
        <el-form-item label="添加时间">
            <el-input class="small-input" placeholder="请选择日期" suffix-icon="el-icon-date" v-model="addForm.time1">
            </el-input>
            <el-input class="small-input" placeholder="请选择时间" suffix-icon="el-icon-timer" v-model="addForm.time2">
            </el-input>
        </el-form-item>
        <el-form-item label="当前操作员" prop="currOperator">
            <el-input class="small-input" v-model="addForm.currOperator" placeholder="管理员user"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark">
            <el-input type="textarea" :rows="2" style="width: 286px" v-model="addForm.remark" placeholder="请输入200字以内的备注信息"></el-input>
        </el-form-item>
    </el-form>
    </div>
</template>

<script>
    export default {
        name: "新增数据域",
        data() {
            return {
                coaloptions: [{
                    value: '选项1',
                    label: '王家岭项目'
                }, {
                    value: '选项2',
                    label: '张家岭项目'
                }, {
                    value: '选项3',
                    label: '巴拉素项目'
                }],
                systemoptions: [{
                    value: '选项1',
                    label: '系统1'
                }, {
                    value: '选项2',
                    label: '系统2'
                }, {
                    value: '选项3',
                    label: '系统3'
                }],
                subsystemoptions: [{
                    value: '选项1',
                    label: '子系统1'
                }, {
                    value: '选项2',
                    label: '子系统2'
                }, {
                    value: '选项3',
                    label: '子系统3'
                }],
                deviceoptions: [{
                    value: '选项1',
                    label: '设备1'
                }, {
                    value: '选项2',
                    label: '设备2'
                }, {
                    value: '选项3',
                    label: '设备3'
                }],
                // 添加数据域的表单数据
                addForm: {
                    datafileName: '',
                    coalValue: '',
                    systemValue: '',
                    subsystemValue: '',
                    deviceValue: '',
                    datafileDes: '',
                    time1: '',
                    time2: '',
                    currOperator: '',
                    remark: ''
                },
                addFormRules: {
                    datafileName: [{
                        required: true,
                        message: '数据域名称不能为空',
                        trigger: 'blur'
                    }],
                    datafileDes: [{
                        required: true,
                        message: '数据域简介不能为空',
                        trigger: 'blur'
                    }]
                }



            }
        }
    }
</script>

<style scoped>
    .centered {
        margin-left: 30px;
    }

    .add {
        width: 150px;
        margin-top: 15px;
    }
</style>
